.raceadministrator .material-icons-outlined:hover {
    background: transparent;
  }

  .raceadministrator .eventInner {padding: 0;}
  .raceadministrator .districtSelectBox {padding: 0;}
  .raceadministrator .searchBox {width: 100%; position: relative; overflow: visible;}
  .raceadministrator .eventInner.membersList-box .eventForm {position: absolute; left: 0; top: 100%; z-index: 100000; background: #fff;
}
  .closeBtn {display: none;}
  .raceadministrator .entriesButton{
    height: 26px;
  }

  
@media(max-width:1200px) {
  .raceadministrator {position: fixed; width: 320px; height: 100%; z-index: 1001; overflow: auto; background: #fff; top: 0; right: -320px; transition: ease-in-out .4s;}
  .raceadministrator.active {right: 0;}
  .closeBtn {display: block; position: absolute; top: 35px; right: 5px;}
  .raceadministrator .userName {display: block}
}